import React from 'react';
import Login from '../Login/Login';

const Home = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default Home;
